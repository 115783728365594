
import { defineComponent, ref, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

// import LangSelector from "./LangSelector.vue";

export default defineComponent({
  name: "Header",
  components: {
    // LangSelector
  },
  setup() {
    const { dispatch } = useStore();
    const router = useRouter();
    const { t, locale } = useI18n({ useScope: "global" });

    const navList = computed(() => [
      {
        route: { name: "Home", params: { lang: locale.value } },
        label: "header.nav.home"
      },
      {
        route: { name: "Portfolio", params: { lang: locale.value } },
        label: "header.nav.portfolio"
      },
      {
        label: "header.nav.growthCalculators",
        items: [
          {
            route: {
              name: "Calculator",
              params: {
                lang: locale.value,
                slug: "head-circumference-age-zero-to-five"
              }
            },
            label: "calculators.headCircumference"
          },
          {
            route: {
              name: "Calculator",
              params: { lang: locale.value, slug: "height-age-two-to-five" }
            },
            label: "calculators.height"
          },
          {
            route: {
              name: "Calculator",
              params: { lang: locale.value, slug: "lenght-age-zero-to-two" }
            },
            label: "calculators.length"
          },
          {
            route: {
              name: "Calculator",
              params: { lang: locale.value, slug: "weight-age-zero-to-five" }
            },
            label: "calculators.weight"
          },
          {
            route: {
              name: "Calculator",
              params: { lang: locale.value, slug: "weight-height-two-to-five" }
            },
            label: "calculators.weightHeight"
          },
          {
            route: {
              name: "Calculator",
              params: { lang: locale.value, slug: "weight-length-zero-to-two" }
            },
            label: "calculators.weightLength"
          }
        ]
      },
      {
        label: "header.nav.library",
        items: [
          {
            route: { name: "Publications", params: { lang: locale.value } },
            label: "header.nav.publications"
          },
          {
            route: {
              name: "ScientificBrochures",
              params: { lang: locale.value }
            },
            label: "header.nav.scientificBrochures"
          },
          {
            route: { name: "VideosLibrary", params: { lang: locale.value } },
            label: "header.nav.videos"
          },
          {
            route: { name: "Links", params: { lang: locale.value } },
            label: "header.nav.links"
          }
        ]
      },
      {
        label: "header.nav.materialsForParents",
        items: [
          {
            route: { name: "Poop", params: { lang: locale.value } },
            label: "header.nav.healthyStools"
          },
          {
            route: {
              name: "ComplementaryFeeding",
              params: { lang: locale.value }
            },
            label: "header.nav.complementaryFeeding"
          },
          // LACTATION DOES NOT EXIST IN GEORGIA OR AZERBAINJAN
          // {
          //   route: { name: "Lactation", params: { lang: locale.value } },
          //   label: "header.nav.breastfeeding"
          // },
          // {
          //   route: { name: "VideosMaterials", params: { lang: locale.value } },
          //   label: "header.nav.videos"
          // },
          {
            route: { name: "Brochures", params: { lang: locale.value } },
            label: "header.nav.brochures"
          }
        ]
      },
      // EASY TRACKING DOES NOT EXIST IN GEORGIA OR AZERBAINJAN
      // {
      //   route: { name: "EasyTracking", params: { lang: locale.value } },
      //   label: "header.nav.easyTracking"
      // },
      {
        route: { name: "About", params: { lang: locale.value } },
        label: "header.nav.aboutFriso"
      }
    ]);

    const logout = () => {
      dispatch("logout");
      router.push({ name: "Login", params: { lang: locale.value } });
    };

    // ---- MOBILE NAV ---- //
    const showNavMobile = ref(false);
    const isSubNavActive = ref(new Array(navList.value.length));
    const activateSubNav = (idx: number) => {
      for (let i = 0; i < isSubNavActive.value.length; i++) {
        if (i === idx) isSubNavActive.value[i] = !isSubNavActive.value[i];
        else isSubNavActive.value[i] = false;
      }
    };
    const openNavMobile = () => {
      showNavMobile.value = true;
    };
    const closeNavMobile = () => {
      showNavMobile.value = false;
    };
    // ---- /MOBILE NAV ---- //

    const showUserMenu = ref(false);
    const toggleUserMenu = () => {
      showUserMenu.value = !showUserMenu.value;
    };
    const hideUserMenu = () => {
      showUserMenu.value = false;
    };

    return {
      t,
      locale,
      navList,
      showNavMobile,
      openNavMobile,
      closeNavMobile,
      logout,
      isSubNavActive,
      activateSubNav,
      toggleUserMenu,
      showUserMenu,
      hideUserMenu
    };
  }
});
