export default {
  "common": {
    "completeGuide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete Guide"])},
    "selectBottomButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the button below to read or share the content."])},
    "learnMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn&nbsp;more!"])},
    "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share"])}
  },
  "header": {
    "nav": {
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
      "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio"])},
      "growthCalculators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Growth calculators"])},
      "library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Library"])},
      "publications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publications"])},
      "scientificBrochures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scientific brochures"])},
      "videos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Videos"])},
      "links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Links"])},
      "materialsForParents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Materials for parents"])},
      "healthyStools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Healthy Stools"])},
      "complementaryFeeding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complementary feeding"])},
      "brochures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brochures"])},
      "breastfeeding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Breastfeeding"])},
      "easyTracking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EasyTracking"])},
      "aboutFriso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Friso"])}
    }
  },
  "footer": {
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exclusive material for the health professional. Breastfeeding is the best food for the healthy growth and development of the infant."])},
    "textB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stronger on the inside so we can experience more together!"])},
    "textC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find us at:"])},
    "textD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["all rights reserved"])},
    "textE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The content of this web site, including, but not limited to, our core brands FRISO<sup>&reg;</sup> and FRISOLAC<sup>&reg;</sup>, is the protected intellectual property of FrieslandCampina."])}
  },
  "about": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Friso"])},
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso<sup>&reg;</sup> is a global brand that is produced and packed in the Netherlands by FrieslandCampina, one of the world’s leading dairy companies. The milk in our Friso<sup>&reg;</sup> products comes from our own farms. We take special care on our farms, from the quality of our soil to the feeding of cows. Our farmers come from proud heritage farming families with over 150 years of dairy experience."])},
    "textB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The 360° videos will show you all the knowledge, professionalism and passion that over 23.000 colleagues put in every day."])},
    "textC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This tour will take you to the beautiful Dutch meadows at dawn. After experiencing the farm, you will visit the state-of- the-art Innovation Centre and get a tour through one of the most important infant formula production plants. Finally, you will experience a typical day in the life of a Friso<sup>&reg;</sup> mother and her daughter. In short, a tour through the entire Friso<sup>&reg;</sup> journey: from Grass to Glass."])},
    "textD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To create the high quality infant formula, you need healthy cows, but also the best grass growing on the best soil. You can visit the farm yourself, and look around to see with how much passion the Vollering family runs their farm and take good care of their herd."])},
    "textE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso<sup>&reg;</sup> formula is based on the high quality milk we produce. It needs to be adapted to meet the nutritional requirements of infants. Here, specialists from different fields of expertise work together to constantly improve on our products. "])},
    "textF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FrieslandCampina has control over the entire production process. We receive fresh milk directly from our farms and only have to gently heat the milk once, unlike other manufacturers. This is unique in the infant formula industry and creates a great advantage: it makes the final product easier to digest and all the essential nutrients remain intact."])},
    "textG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When you visit the Netherlands you need to have a bike! You can join Sietske and her daughter Zara on their bike to ride through a typical Dutch landscape and see some sights along the way."])},
    "textH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for joining us on this tour: Made by Nature, Made better by Science, in which you have witnessed that Friso<sup>&reg;</sup> is the only brand that has the entire production process in its hands, from Grass to Glass."])},
    "textI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whenever you are in the Netherlands, you are more than welcome to visit one of our farms."])},
    "textJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tot ziens! (Goodbye!)"])},
    "subtitleA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Made by Nature"])},
    "subtitleB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Made better by Science"])},
    "subtitleC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unique processing"])},
    "subtitleD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to the Netherlands!"])},
    "popupText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The 360 effect of the video is only working when using the YouTube application. Please make sure this is working on your phone before you start watching."])},
    "popupAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])}
  },
  "easyTracking": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EasyTracking"])},
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What is the EasyTrack System?"])},
    "textB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starting from our own farms in the Netherlands, you can follow the rigorous controls at every step in the production of the Friso formula."])},
    "cardA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our<br/>Farms"])},
    "cardB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Milk<br/>Collection"])},
    "cardC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packaging"])},
    "cardD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Final quality<br/>control"])},
    "cardE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export<br/>Approval"])}
  },
  "poop": {
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good digestion is important for your child's growth and development. The most visible indicator of your child's digestive health is... his or her stool! Here's how you can help your child achieve good poops."])},
    "colors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colors"])},
    "shapes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shapes"])},
    "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good"])},
    "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bad"])},
    "goodColorsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good Colors"])},
    "goodColorsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These <b>colors</b> are normal variations."])},
    "greenishCoffee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greenish Coffee"])},
    "coffee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coffee"])},
    "coffeeYellow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yellow Coffee"])},
    "darkGreen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dark Green"])},
    "badColorsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bad Colors"])},
    "badColorsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These <b>colors</b> may indicate signs of bleeding or digestion problems in infants."])},
    "red": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Red"])},
    "black": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Black"])},
    "white": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["White"])},
    "withRedBars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With Red Stripes"])},
    "goodShapesTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good Shapes"])},
    "goodShapesText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These are the ideal <b>shapes</b> that can be easily expelled."])},
    "spongy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spongy Corn"])},
    "sausage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soft Sausage"])},
    "badShapesTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inadequate Shapes"])},
    "badShapesText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These <b>shapes</b> indicate signs of dehydration, insufficient fiber, inadequate diet and/or stress."])},
    "parsley": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parsley"])},
    "parsleyImageName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["parsley_en"])},
    "oats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oats"])},
    "grape": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grapes"])},
    "chicken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chicken Nuggets"])},
    "rabbit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rabbit Stool"])}
  },
  "complementaryFeeding": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complementary feeding"])},
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relationship between neurodevelopment and nutrition"])},
    "imageAUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/complementary-feeding/table-desktop_en.png"])},
    "imageA2xUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/complementary-feeding/table-desktop2x_en.png"])}
  },
  "breatsfeeding": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Breastfeeding"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctors recommend exclusive breastfeeding for at least the first six months of life."])},
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The World Health Organization (WHO) recommends exclusive breastfeeding for six months, the introduction of age-appropriate and safe foods thereafter, and continued breastfeeding until 2 years of age or beyond."])},
    "textB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This may vary according to the different circumstances of the moms: many will have to return to work earlier, others may have some more will want to make their babies more independent from an early age or share the responsibility of feeding with the father so that they also experience the connection."])},
    "textC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Society puts pressure on how and when to feed your newborn. In this sense, no one can tell you what is best for your baby, as only you will know better than anyone else how their life patterns and habits are. However, it is a fact that breast milk is the best food for your baby, so we advise you to try to breastfeed your baby for at least the first six months of life."])}
  },
  "materialsVideos": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Videos"])},
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select videos to show topics of interest to parents of patients."])},
    "videos": {
      "a": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benefits of lactose"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["beneficios-de-la-lactosa-consumidores.mp4"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/videos-materials/video-preview-b.png"])}
      },
      "b": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How do I know if my baby is lactose intolerant?"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["video-lactosa-para-consumidores-pisa.mp4"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/videos-materials/video-preview-c.png"])}
      }
    }
  },
  "publications": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publications"])},
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select articles to learn more about the science behind Friso products."])},
    "articles": {
      "a": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decreased Occurrence of Gastrointestinal Symptoms in Chinese Infants Fed a Commercially Available Minimally Processed Formula: A Cross-sectional Observational Study"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xiao Yang Sheng, Vanitha Buthmanaban, Glenn A.A. van Lieshout and Panam Parikh"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2020-sheng-et-al-jnme-disminucion-de-sintomas-gastrointestinales-en-lactantes-alimentados-con-una-formula-minimamente-procesada.pdf"])}
      },
      "b": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Importance of Lactose in the Human Diet: Results of a Mexican Consensus Meeting"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enrique Romero-Velarde, Dagoberto Delgado-Franco, Mariana García-Gutiérrez, Carmen Gurrola-Díaz, Alfredo Larrosa-Haro, Ericka Montijo-Barrios, Frits AJ Muskiet, Belinda Vargas-Guerrero and Jan Geurts"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["consenso-de-lactosa-traduccion-documento.pdf"])}
      },
      "c": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Influence of micellar calcium phosphate on in vitro gastric coagulation and digestion of milk proteins in infant formula model systems"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thorn Huppertz and Tim T. Lambers"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["huppertz-2020-influence-of-calcium-phosphate-on-in-vitro-coagulation-and-digestion-of-milk.pdf"])}
      },
      "d": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How processing can affect milk protein digestion and overall physiological outcomes: a systematic review"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glenn A. A. van Lieshout, Tim T. Lambers, Marjolijn C. E., Bragt and Kasper A. Hettinga"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lieshout-2019-como-el-procesamiento-puede-afectar-la-digestion-de-proteinas-de-la-leche-y-los-resultados-fisiologicos-generales.pdf"])}
      },
      "e": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infant milk formulas differ regarding their allergenic activity and induction of T-cell and cytokine responses"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["H. Hochwallner, U. Schulmeister, I. Swoboda, M. Focke-Tejkl, R. Reininger, V. Civaj, R. Campana, J. Thalhamer, S. Scheiblhofer, N. Balic, F. Horak, M. Ollert, N. G. Papadopoulos, S. Quirce, Z. Szepfalusi, U. Herz, E. A. F. van Tol and S. Spitzauer & R. Valenta"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hochwallner-2016-infant-milk-formuls-differ-regrding-their-allergenic-activity-and-induction-of-t-cell-and-cytokine-responses.pdf"])}
      },
      "f": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feeding the Late and Moderately Preterm Infant: A Position Paper of the European Society for Paediatric Gastroenterology, Hepatology and Nutrition Committee on Nutrition"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alexandre Lapillonne, Jiri Bronsky, Cristina Campoy, Nicholas Embleton, Mary Fewtrell, Nataša Fidler Mis, Konstantinos Gerasimidis, Iva Hojsak, Jessie Hulst, Flavia Indrio, Christian Molgaard, Sissel Jennifer Moltu, Elvira Verduci and Magnus Domellöf"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["feeding-the-late-and-moderately-preterm-infant-lapillone-2019_en.pdf"])}
      },
      "g": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strategies and Future Opportunities for the Prevention, Diagnosis, and Management of Cow Milk Allergy"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benjamin Zepeda-Ortega, Anne Goh, Paraskevi Xepapadaki, Aline Sprikkelman, Nicolaos Nicolaou, Rosa Elena Huerta Hernandez, Amir Hamzah Abdul Latiff, Miu Ting Yat, Mohamed Diab, Bakr Al Hussaini, Budi Setiabudiawan, Urszula Kudla, R. J. Joost van Neerven, Leilani Muhardi and John O. Warner"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["strategies-and-future-opportunities-for-the-prevention-diagnosis-and-management-of-cow-milk-allergy_en.pdf"])}
      },
      "h": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Narrative Review on the Update in the Prevalence of Infantile Colic, Regurgitation, and Constipation in Young Children: Implications of the ROME IV Criteria"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leilani Muhardi<sup>1</sup>, Marion M. Aw<sup>2,3</sup>, Mohammed Hasosah<sup>4,5</sup>, Ruey Terng Ng<sup>6</sup>, Sze Yee Chong<sup>7</sup>, Badriul Hegar<sup>8</sup>, Erick Toro-Monjaraz<sup>9</sup>, Andy Darma<sup>10</sup>, Merih Cetinkaya<sup>11</sup>, Chung Mo Chow<sup>12</sup>, Urszula Kudla<sup>13</sup> and Yvan Vandenplas<sup>14*</sup>"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2022-muhardi-et-al-a-narrative-review-on-colic-reflux-and-constipation.pdf"])}
      },
      "i": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Milk protein coagulation under gastric conditions: A review"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thom Huppertz<sup>a,b,*</sup>, Loo Wee Chiaa<sup>a</sup><br/><sup>a</sup> FrieslandCampina, Amersfoort, the Netherlands<br/><sup>b</sup> Wageningen University & Research, Wageningen, the Netherlands"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2020-huppertz-et-al-milk-protein-coagulation-under-gastric-conditions.pdf"])}
      }
    }
  },
  "links": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Links"])},
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum dolor sit amet, adipiscing quis non in mollit (EN)"])},
    "items": {
      "a": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso"])},
        "href": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.friso.ge"])}
      },
      "b": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso International"])},
        "href": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.frieslandcampinainstitute.com"])}
      },
      "c": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso Webshop"])},
        "href": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["#friso-webshop"])}
      }
    }
  },
  "scientificBrochures": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scientific brochures"])},
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose topics of interest to learn more about infant and child feeding."])},
    "articles": {
      "a": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulas of<br/>Friso Routine"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["triptico-frisolac-friso.pdf"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/scientific-brochures/item-a.png"])}
      },
      "b": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allergy System<br/>Frisolac Gold"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sistema-de-alergias-frisolac-gold-movil.pdf"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/scientific-brochures/item-b.png"])}
      },
      "c": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importance of Lactose<br/>in the Human Diet"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["importancia-de-la-lactosa-en-la-dieta-humana.pdf"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/scientific-brochures/item-c.png"])}
      },
      "d": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guide for good<br/>supplemental nutrition"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["manual-alimentacion-complementaria.pdf"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/scientific-brochures/item-d.png"])}
      },
      "e": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Milk formula with different glycation profile influences gastrointestinal outcomes in Chinese infants"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["milk-formula-with-different-glycation-profile-influences-gi-outcomes-in-chinese-infants_en.pdf"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/scientific-brochures/item-e.png"])}
      },
      "f": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All About Friesland Campina (GE)"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["all-about-us-new-brochure-2020_ge.pdf"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/scientific-brochures/item-f.png"])}
      },
      "g": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio (GE)"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["notebook-2022_ge.pdf"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/scientific-brochures/item-g.png"])}
      },
      "h": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso e-detailer (GE)"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["friso-e-detailer_ge.pdf"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/scientific-brochures/item-h.png"])}
      },
      "i": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso AR (GE)"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["friso-ar_ge.pdf"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/scientific-brochures/item-i.png"])}
      },
      "j": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso BIB (GE)"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["friso-bib_ge.pdf"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/scientific-brochures/item-j.png"])}
      },
      "k": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["საუკეთესო ბუნებრივი თვისებებით შექმნილი ნარევი მინდვრიდან ფინჯნამდე"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["leaflet-2023_ge.pdf"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/scientific-brochures/item-k.png"])}
      }
    }
  },
  "brochures": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brochures"])},
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose topics of interest."])},
    "articles": {
      "a": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patients' guide for the management of Cows' Milk Protein Allergy (CMPA)"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["booklet-cows-milk-allergy_en.pdf"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/brochures/item-a.png"])}
      },
      "b": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patients' guide for the management of Functional Gastrointestinal Disorders (FGIDs)"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["booklet-functional-gastrointestinal-disorders_en.pdf"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/brochures/item-b.png"])}
      }
    }
  },
  "libraryVideos": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Videos"])},
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select videos to show topics of interest to parents of patients."])},
    "videos": {
      "a": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digestion of milk fat"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["digestion-de-la-grasa-lactea.mp4"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/videos-library/video-preview-a.png"])}
      },
      "b": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reduce lactose? Think twice!"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reducir-la-lactosa-nuevo-logo-hcp.mp4"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/videos-library/video-preview-b.png"])}
      },
      "c": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lactose intolerance"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["intolerancia-a-la-lactosa-nuevo-logo-hcp.mp4"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/videos-library/video-preview-c.png"])}
      },
      "d": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friesland Campina - Farmer Story"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["frieslandcampina-farmer-story_en.mp4"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/videos-library/video-preview-d.png"])}
      },
      "e": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strategies and future opportunities for the prevention, diagnosis, and management of cow milk allergy"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["frieslandcampina-publication-series-zepeda-et-al-strategies-and-future-opportunities-for-the-prevention-diagnosis-and-management-of-cow-milk-allergy_en.mp4"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/videos-library/video-preview-e.png"])}
      },
      "f": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Single vs. Double Processing"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["glycation-single-versus-double-processing_en.mp4"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/videos-library/video-preview-f.png"])}
      },
      "g": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lactose - Part I"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lactose-part-i_en.mp4"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/videos-library/video-preview-g.png"])}
      },
      "h": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lactose - Part II"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lactose-part-ii_en.mp4"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/videos-library/video-preview-h.png"])}
      },
      "i": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allergy Management"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["allergy-management_en.mp4"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/videos-library/video-preview-i.png"])}
      },
      "j": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friesland Gut health - Part 1"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["friesland-gut-health-part-1_en.mp4"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/videos-library/video-preview-j.png"])}
      },
      "k": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friesland Gut health - Part 2"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["friesland-gut-health-part-2_en.mp4"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/videos-library/video-preview-k.png"])}
      },
      "l": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friesland Gut health - Part 3"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["friesland-gut-health-part-3_en.mp4"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/videos-library/video-preview-l.png"])}
      }
    }
  },
  "calculators": {
    "banner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Growth calculators for the healthy child."])},
    "boy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boy"])},
    "girl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Girl"])},
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in the form with the baby's details"])},
    "buttonA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculate"])},
    "percentile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percentile"])},
    "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Result"])},
    "headCircumference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Head circumference"])},
    "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Height"])},
    "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Length"])},
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight"])},
    "weightHeight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight ─ Height"])},
    "weightLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight ─ Length"])},
    "dateBirth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birth date"])},
    "dateMeasure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Measure date"])},
    "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Age"])},
    "mounths": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mounths"])},
    "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["years"])},
    "ageRangeYears": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["From ", _interpolate(_list(0)), " to ", _interpolate(_list(1)), " years"])}
  },
  "home": {
    "banners": {
      "a": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Friso AR"])},
        "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For reflux: with carob bean gum and hydrolysed protein"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/home/slides/1/packshot_en.png"])}
      },
      "b": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculators"])},
        "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With the WHO seal of confidence, I was now able to calculate the growth percentiles of babies instantly."])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/home/slides/2/device_en.png"])}
      }
    },
    "columns": {
      "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover our complete range of products and choose the right option for your baby."])},
      "textB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check out the latest scientific materials we have developed for you, so that you are always up to date on innovation and scientific advances."])},
      "textC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help parents, even from a distance, by sharing these educational materials."])}
    },
    "app": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our new application is finally here for you!"])},
      "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are very happy to announce that our new App is now available for download for iPhone or Android phones!"])},
      "imageAUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/home/app-devices_en.png"])},
      "imageBUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/home/qr-code_en.png"])}
    },
    "contact": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't have any more doubts..."])},
      "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write your email and we will contact you as soon as possible."])},
      "inputA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENTER YOUR E-MAIL ADDRESS"])},
      "inputB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WRITE YOUR QUESTION HERE"])},
      "buttonA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request contact"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sent!"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error has occurred!"])},
      "sending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sending..."])}
    }
  },
  "login": {
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This website is intended exclusively for healthcare professionals."])},
    "textB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If your access is already granted, enter the details below.<br/>If you are a healthcare professional and do not have the credentials for this website,<br/><u>ask your Friso contact person</u>."])},
    "inpUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
    "inpPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "invalidCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid credentials!"])}
  },
  "portfolio": {
    "banner": {
      "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Breastfeeding is the best food for the healthy growth and development of the infant."])},
      "textB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When breastfeeding is not an option, our product range presents solutions for every baby, with or without special needs."])}
    },
    "allProducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All products"])},
    "special": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Special"])},
    "regular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regular"])},
    "notice": {
      "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Breastfeeding provides the best nutrition for healthy growth and development of infants. Mothers should receive guidance regarding proper maternal nutrition to help maintain adequate intake and quality breast milk. Unnecessary introduction of partial or complete formula feeding or other complementary foods or beverages can have a negative effect on breastfeeding, which may be irreversible. Mothers should consult their doctor and consider the social and economic implications before deciding to use breast milk substitutes or if they have difficulty breastfeeding. Instructions for use, preparation and storage of breast milk substitutes should be carefully followed as inappropriate or unnecessary use can be a health risk."])},
      "buttonA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I have read and understand"])}
    }
  },
  "product": {
    "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingredients"])},
    "preparation": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preparation"])},
      "tableTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suggested feeding chart"])},
      "table": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["<img class=\"table\" src=\"", _interpolate(_list(0)), "\"/><p><strong>1 level scoop is approx. ", _interpolate(_list(1)), " grams.<br/>Use of unboiled water, unsterilized cup or containers or improper preparation of the product may be harmful to your child.</strong></p>"])},
      "instructions": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directions for use"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the interest of your baby's health, please follow these instructions.<br>Boil all utensils for at least three minutes once a day."])},
        "number1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wash hands and utensils thoroughly, using clean water."])},
        "number2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Boil clean water for 5 minutes and allow cooling to approx ", _interpolate(_list(0)), "°C."])},
        "number3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Just before use, rinse the bottle and teat thoroughly with hot water."])},
        "number4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour the indicated amount of water into the bottle."])},
        "number5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use enclosed scoop to add 1 level scoop to each 30 mL of water."])},
        "number6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stir or shake well until the powder is completely dissolved."])},
        "number7": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Cool ", _interpolate(_list(0)), " down rapidly. Test the temperature and feed your baby when formula is lukewarm (37°C)."])},
        "number8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish using reconstituted feeding within max. 1 hour after preparation. Discard any unfinished feeding."])},
        "number9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use the reconstituted formula within 1 hour, at the latest, after its preparation."])},
        "number10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discard any unconsumed intake."])},
        "warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Attention:<br/>Use prepared ", _interpolate(_list(0)), " within a maximum of 1 hour after preparation."])}
      }
    },
    "nutricionalTable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutrition Information"])},
    "frisolacGold1": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frisolac Gold 1"])},
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frisolac<sup>®</sup> Gold 1"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Easily digestible protein"])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From 0 to 6 months"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul><li>No palm oil added to Frisolac Gold 1</li><li>Frisolac Gold 1 has LockNutri technology: minimal processing limits the glycation of protein, showing a positive effect on digestion</li><li>Literature has shown that prebiotic GOS stimulates the growth of beneficial bacteria and helps to maintain gut health</li><li>DHA and ARA, support learning and cognitive development</li><li>Contains nucleotides to support immunity</li></ul>"])},
      "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demineralized whey, Skimmed milk, Vegetable oils (sunflower, Canola oil (low erucic acid type), coconut), Lactose, Galacto-oligosaccharides, Maltodextrin, Fish oil, Choline chloride, Single cell oil (Mortierella alpina), Taurine, Sunflower Lecithin (emulsifier), Minerals: Calcium Phosphate, Potassium Chloride, Calcium Carbonate, Sodium chloride, Magnesium Citrate, Iron Sulfate, Calcium Hydroxide, Zinc Sulfate, Copper Sulfate, Manganese Sulfate, Potassium Iodide, Sodium Selenite, Vitamins: Sodium L-ascorbate, Mixed tocopherols, L-ascorbyl-palmitate, DL-alpha-tocopheryl acetate, Nicotinamide, Calcium D-pantothenate, Thiamine hydrochloride, Retinyl acetate, Pyridoxine hydrochloride, Folic acid, Riboflavin, Phytomenadione, D-Biotin, Cholecalciferol, Nucleotides: Disodium inosine-5-monophosphate, Disodium uridine 5-monophosphate, Cytidine-5-monophosphoric acid, Adenosine-5-monophosphoric acid, Disodium guanosine-5-monophosphate."])},
      "preparationTableUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/products/preparation-table-2_az.svg"])}
    },
    "frisolacGold2": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frisolac Gold 2"])},
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frisolac<sup>®</sup> Gold 2"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Easily digestible protein"])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From 6 to 12 months"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul><li>No palm oil added to Frisolac Gold 2</li><li>Frisolac Gold 2 has LockNutri technology: minimal processing limits the glycation of protein, showing a positive effect on digestion</li><li>Literature has shown that prebiotic GOS stimulates the growth of beneficial bacteria and helps to maintain gut health</li><li>DHA and ARA, support learning and cognitive development</li><li>Contains nucleotides to support immunity</li></ul>"])},
      "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demineralized whey, Skimmed milk, Vegetable oils (sunflower, Canola oil(low erucic acid type), coconut), Lactose, Galacto-oligosaccharides, Maltodextrin, Fish oil, Single cell oil (Mortierella alpina), Choline chloride, Taurine, Sunflower Lecithin (emulsifier), Minerals: Calcium Phosphate, Potassium Chloride, Calcium Carbonate, Sodium Chloride, Magnesium Citrate, Iron Sulfate, Calcium Hydroxide, Zinc Sulfate, Copper Sulfate, Manganese Sulfate, Potassium Iodide, Sodium Selenite, Vitamins: Sodium L-ascorbate, Mixed tocopherols, L-ascorbyl-palmitate, DL-alpha-tocopheryl acetate, Nicotinamide, Calcium D-pantothenate, Thiamine hydrochloride, Retinyl acetate, Pyridoxine hydrochloride, Riboflavin, Folic acid, Phytomenadione, D-Biotin, Cholecalciferol, Nucleotides: Disodium inosine-5-monophosphate, Disodium uridine 5-monophosphate, Cytidine-5-monophosphoric acid, Adenosine-5-monophosphoric acid, Disodium guanosine-5-monophosphate."])},
      "preparationTableUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/products/preparation-table-4_en.png"])}
    },
    "frisolacGold3": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso Gold 3"])},
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso<sup>®</sup> Gold 3"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Easily digestible protein"])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From 1 to 3 years"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul><li>No palm oil added to Friso Gold 3</li><li>Friso Gold 3 has LockNutri technology: minimal processing limits the glycation of protein, showing a positive effect on digestion</li><li>Literature has shown that prebiotic GOS stimulates the growth of beneficial bacteria and helps to maintain gut health</li><li>Contains nucleotides to support immunity</li><li>DHA and ARA support learning and development</li></ul>"])},
      "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skimmed milk, Glucose syrup solids, Vegetable oils (sunflower, Canola (low erucic acid type), coconut), Sucrose, Lactose, Galacto-oligosaccharides, Fish oil, Choline chloride, Single cell oil (Mortierella Alpina), Meso-inositol, Taurine, Lecithin (emulsifier), Minerals: Calcium Carbonate, Ferrous sulfate, Zinc Sulfate, Manganese Sulfate, Copper Sulfate, Sodium Selenate, Potassium Iodide, Vitamins: Sodium L-ascorbate, Mixed tocopherols, L-ascorbyl-palmitate, DL-alpha-tocopheryl acetate, Nicotinamide, Calcium D-pantothenate, Retinyl acetate, Riboflavin, β-carotene, Thiamin hydrochloride, Pyridoxine hydrochloride, Folic Acid, Phytomenadione, Cholecalciferol, D-Biotin, Nucleotides: Disodium uridine 5-monophosphate, Cytidine-5-monophosphoric acid, Disodium inosine-5-monophosphate, Adenosine-5-monophosphoric acid, Disodium guanosine-5-monophosphate."])},
      "preparationTableExtra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Recommended intake is 2-3 glasses per day.</p><p>The use of unboiled water, unsterilized cups or containers, or improper product preparation can be harmful to your child.</p><img class=\"table\" src=\"/img/products/preparation-table-7_en.png\"/>"])}
    },
    "frisoVom1": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frisolac Gold Vom 1 Comfort"])},
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frisolac<sup>®</sup> Gold Vom 1 Comfort"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutritional management of vomiting, constipation and colic"])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From 0 to 6 months"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul><li>Frisolac Gold Vom 1 Comfort – Comfort has LockNutri: minimal processing limits the glycation of protein, showing a positive effect on digestion</li><li>Frisolac Gold Vom 1 Comfort - Comfort with Carob Bean Gum (CBG) is effective in reducing the incidence of vomiting, constipation and colic</li><li>Carob bean gum has been shown to be effective for:<ul><li>Colics – CBG and GOS both stimulate the growth of beneficial intestinal microflora</li><li>Constipation – CBG helps to soften the stool and normalizes its frequency</li><li>Vomiting – CBG thickens the mixture, thereby reducing the volume and frequency of vomiting</li></ul></li><li>DHA and ARA support learning and development</li></ul>"])},
      "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skimmed milk, Demineralized whey, Vegetable oils (palm, palm kernel, canola (low-erucic acid type), sunflower), Lactose, Carob bean gum, Galacto-oligosaccharides, Maltodextrin, Whey protein concentrate, Fish oil, Choline chloride, Single cell Oil (Mortierella alpina), Meso-inositol, Taurine, Sunflower Lecithin (emulsifier), Minerals (Potassium citrate, Tri-calcium phosphate, Calcium carbonate, Sodium chloride, Magnesium citrate, Potassium chloride, Calcium hydroxide, Ferrous sulfate, Zinc sulfate, Cupric sulfate, Manganese sulfate, Potassium iodide, Sodium selenite), Vitamins (Sodium L-ascorbate, L-Ascorbyl palmitate, Mixed tocopherol concentrate, Calcium D-pantothenate, DL alpha-tocopheryl acetate, Nicotinamide, Thiamin hydrochloride, Riboflavin, Retinyl-acetate, Pyridoxine hydrochloride, Folic acid, Phytomenadione, D-Biotin, Cholecalciferol), Nucleotides (Disodium uridine-5’-monophosphate, Cytidine-5’-monophosphoric acid, Disodium inosine-5’-monophosphate, Adenosine-5’-monophosphoric acid, Disodium guanosine-5’-monophosphate)."])},
      "preparationTableExtra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<img class=\"table\" src=\"/img/products/preparation-table-9_en.png\"/><p>1 levelled scoop is aprox. 4,3 grams<br/>100ml of ready Infant Formula ~ 13g of powder (3 levelled scoops) + 90ml of watter</p><p>* Consult your pediatrician for feeding scheme for your baby</p>"])}
    },
    "frisoVom2": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frisolac Gold Vom 2 Comfort"])},
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frisolac<sup>®</sup> Gold Vom 2 Comfort"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutritional management of vomiting, constipation and colic"])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From 6 to 12 months"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul><li>Frisolac Gold Vom 2 Comfort – Comfort has LockNutri: minimal processing limits the glycation of protein, showing a positive effect on digestion</li><li>Frisolac Gold Vom 2 Comfort - Comfort with Carob Bean Gum (CBG) is effective in reducing the incidence of vomiting, constipation and colic</li><li>Carob bean gum has been shown to be effective for:<ul><li>Colics – CBG and GOS both stimulate the growth of beneficial intestinal microflora</li><li>Constipation – CBG helps to soften the stool and normalizes its frequency</li><li>Vomiting – CBG thickens the mixture, thereby reducing the volume and frequency of vomiting</li></ul></li><li>DHA and ARA support learning and development</li></ul>"])},
      "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skimmed milk, Demineralized whey, Vegetable oils (palm, palm kernel, canola (low-erucic acid type), sunflower), Lactose, Carob bean gum, Galacto-oligosaccharides, Maltodextrin, Fish oil, Choline chloride, Single cell Oil (Mortierella alpina), Taurine, Sunflower Lecithin (emulsifier), Minerals (Dicalcium phosphate, Potassium citrate, Tri-calcium phosphate, Calcium carbonate, Sodium chloride, Magnesium citrate, Potassium chloride, Magnesium hydrogen phosphate, Ferrous sulfate, Calcium hydroxide, Zinc sulfate, Cupric sulfate, Manganese sulfate, Potassium iodide, Sodium selenite), Vitamins (Sodium L-ascorbate, L-Ascorbyl palmitate, Mixed tocopherol concentrate, Calcium D-pantothenate, DL alpha-tocopheryl acetate, Nicotinamide, Thiamin hydrochloride, Riboflavin, Retinyl-acetate, Pyridoxine hydrochloride, Folic acid, Phytomenadione, D-Biotin, Cholecalciferol), Nucleotides (Disodium uridine-5’-monophosphate, Cytidine-5’-monophosphoric acid, Disodium inosine-5’-monophosphate, Adenosine-5’-monophosphoric acid, Disodium guanosine-5’-monophosphate)."])},
      "preparationTableExtra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<img class=\"table\" src=\"/img/products/preparation-table-10_en.png\"/><p>1 levelled scoop is aprox. 4,3 grams<br/>100ml of ready Infant Formula ~ 13g of powder (3 levelled scoops) + 90ml of watter</p><p>* Consult your pediatrician for feeding scheme for your baby<br/>** After the age of 6 months your baby needs some extra's.</p>"])}
    },
    "frisolacGoldPremature": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frisolac Gold Premature"])},
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frisolac<sup>®</sup> Gold Premature"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For premature and low birthweight infants."])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From birth onwards till term age or weight"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul><li>To provide high content of protein, energy, micronutrients and PUFAs as recommended by ESPGHAN for preterm infants</li><li>Frisolac Gold Prematuros has partially hydrolysed whey proteins and extensively hydrolysed casein proteins</li><li>PUFAs DHA, EPA, AA support visual and cognitive development</li><li>MCT fats support the energy needs</li><li>Prebiotic GOS is known from research to be beneficial for good gut microbiota</li></ul>"])},
      "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Milk solids, Lactose, Whey protein hydrolysate, Vegetable oils, Palm oil , Canola oil (low erucic acid type), Palm kernel oil, MCT oil, Sunflower oil, Maltodextrin, Casein hydrolysate, Emulsifier (Ctric acid esters of mono- and diglycerdides, Galacto-oligosaccharides, Calcium phosphate, Fish oil, Single cell oil, Calcium carbonate, Magnesium chloride, Sodium chloride, Sodium L-ascorbate, Meso-inositol, Choline chloride, Taurine, Ferrous sulphate, DL a-tocopheryl acetate, Zinc sulphate, Nicotinamide, Emulsifier (Sunflower lecithine), L-carnitine, Cytidine-5'-monophosphoric acid, L-Ascorbyl palmitate, Calcium D-pantothenate, Disodium urdine-5'-monophosphate, Adenosine-5'-monophosphoric acid, Disodium guanosine-5'-monophosphate, Retinyl-acetate, Disodium inosine-5'-monophosphate, Cupric sulphate, Riboflavin, Thiamin hydrochloride, Pyridoxine hydrochloride, Folic acid, Potassium iodide, Phytomenadione, Cholecalciferol, Sodium selenite, D-Biotin, Manganese sulphate, Cyanocobalamin"])},
      "preparationTableExtra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>The composition of Frisolac<sup>®</sup> Gold Premature Babies is based on a consumption of 150 ml of prepared formula per kg of weight per day.</p><img class=\"table\" src=\"/img/products/preparation-table-5_en.png\"/><p><strong>1 level scoop is approx. 5.2 grams.</strong></p>"])}
    },
    "frisoPep": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso PEP"])},
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso<sup>®</sup> PEP"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For the treatment of mild to moderate symptoms of cow’s milk protein allergy"])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From 0 to 12 months"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul><li>Friso PEP has extensively hydrolysed whey proteins: around 37% of peptides contain &lt; 3 amino acids.</li><li>GOS for a healthy intestinal flora</li><li>DHA/AA for visual and cognitive development</li><li>Reduced level of lactose, to minimize protein contamination</li><li>With nucleotides for supporting immunity</li></ul>"])},
      "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lactose, Glucose syrup, Vegetable oils (palm, palm kernel, canola (low erucic acid type, sunflower), Whey protein hydrolysate, Glucose syrup solids, Galacto-oligosaccharides, Citric acid of mono-and diglycerides (emulsifier), Choline chloride, Microalgae Oil (C. cohnii), Sintgle cell oil (Mortierella alpina), Meso-inositol, Taurine, Lecithin (Emulsifier), l-carnitine tartrate, Minerals: Calcium salts of ortophosphoric acid, Magnesium chloride, Potassium chloride, Sodium citrate, Sodium chloride, Potassium citrate, Ferrous sulfate, Zinc sulfate, Cupric sulfate, Manganese sulfate, Potassium iodide, Sodium selenite, Vitamins: Sodium L-ascorbate, Calcium D-pantothenate, L - Ascorbyl palmitate, Tocopherol- rich extract, DL-α-tocopherol acetate, Nicotinamide, Retinyl-acetate, Pyridoxine hydrochloride, Thiamine hydrochloride, Riboflavin, Folic acid, Phytomenadione, D-Biotin, Cholecalciferol, Cyancobalamin, Nucleotides (Disodium uridine-5’ - monophosphate, Cytidine-5'-monophosphoric acid, Disodium inosine-5'-monophosphate, Adenosine-5'- monophosphoric acid, Disodium guanosine-5 ' - monophosphate)."])},
      "preparationTableExtra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<img class=\"table\" src=\"/img/products/preparation-table-8_en.png\"/><p>1 levelled scoop is aprox. 4,3 grams<br/>100ml of ready Infant Formula ~ 13g of powder (3 levelled scoops) + 90ml of watter</p><p>* Consult your pediatrician for feeding scheme for your baby<br/>** After the age of 6 months your baby needs some extra's.<br/>*** After the age of 6 months you should add one extra scoop in 180ml of water.</p>"])}
    },
    "frisoPepAc": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso PEP AC"])},
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso<sup>®</sup> PEP AC"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For the treatment of severe symptoms of cow’s milk protein allergy"])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From 0 to 12 months"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul><li>Friso PEP AC has extensively hydrolysed casein proteins: 60% of peptides contain &lt; 3 amino acids.</li><li>Friso PEP AC does not induce T-cell proliferation and proinflammatory cytokine release in vitro</li><li>Lactose free, to avoid any protein contamination</li><li>With nucleotides for supporting immunity</li></ul>"])},
      "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glucose syrup solids, Vegetable oils, Palm oil , Palm kernel oil, Canola oil (low erucic acid type), Sunflower oil, Extensively hydrolysed casein protein, Emulsifier (Ctric acid esters of mono- and diglycerdides, Tricalcium phosphate, Potassium chloride, Magnesium chloride, Calcium carbonate, Dicalcium phosphate, Choline chloride, L-cystine, L-tryptophan, Oil from Mortierella alpina, DHA-rich oil from microalgae Schizochytrium sp., Myo-inositol, Sodium L-ascorbate, Taurine, Ferrous sulphate, Emulsifier (Sunflower lecithin), L-carnitine tartrate, Zinc sulphate, Calcium D-pantothenate, Disodium urdine-5'-monophosphate, DL a-tocopheryl acetate, Cytidine-5'-monophosphoric acid, Adenosine-5'-monophosphoric acid, Disodium inosine-5'-monophosphate, Nicotinamide, Disodium guanosine-5'-monophosphate, Thiamin hydrochloride, Riboflavin, Cupric sulphate, Pyridoxine hydrochloride, Retinyl-acetate, Manganese sulphate, Potassium iodide, Folic acid, Phytomenadione, Sodium selenite, D-Biotin, Cholecalciferol, Cyanocobalamin. <strong>Contains: hydrolyzed cow milk casein.</strong>"])},
      "preparationTableExtra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<img class=\"table\" src=\"/img/products/preparation-table-8_en.png\"/><p>1 levelled scoop is aprox. 4,3 grams<br/>100ml of ready Infant Formula ~ 13g of powder (3 levelled scoops) + 90ml of watter</p><p>* Consult your pediatrician for feeding scheme for your baby<br/>** After the age of 6 months your baby needs some extra's.<br/>*** After the age of 6 months you should add one extra scoop in 180ml of water.</p>"])}
    },
    "frisolacGoldComfortAr": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frisolac Gold Comfort AR"])},
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frisolac<sup>®</sup> Gold Comfort AR"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dietary management of gastroesophageal reflux."])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From 0 to 12 months"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul><li>Frisolac Gold Comfort AR contains the optimal amount of carob bean gum that has proven to be effective in reducing reflux rates</li><li>GOS prebiotics support healthy gut microbiota</li><li>Partially hydrolyzed whey protein accelerates the rate of gastric emptying.</li><li>DHA and ARA support learning and cognitive development</li><li>Full lactose: the optimal carbohydrate for infants</li></ul>"])},
      "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Milk solids, Skimmed milk, Demineralized whey, Lactose, Whey protein concentrate, Vegetable oils, Palm oil , Palm kernel oil, Canola oil (low erucic acid type), Sunflower oil, Whey protein hydrolysate, Carob bean gum, Maltodextrin, Galacto-oligosaccharides, Calcium phosphate, Emulsifier (Ctric acid esters of mono- and diglycerdides, Fish oil, Potassium citrate, Calcium carbonate, Choline chloride, Sodium chloride, Single cell oil, Magnesium citrate, Potassium chloride, Sodium L-ascorbate, Magnesium chloride, Meso-inositol, Taurine, Ferrous sulphate, Emulsifier (Sunflower lecithine), Calcium hydroxide, L-phenylalanine, L-tyrosine, Zinc sulphate, Disodium urdine-5'-monophosphate, L-Ascorbyl palmitate, Calcium D-pantothenate, Antioxidant (Tocopherol-rich extract) , Cytidine-5'-monophosphoric acid, DL a-tocopheryl acetate, Disodium inosine-5'-monophosphate, Nicotinamide, Adenosine-5'-monophosphoric acid, L-carnitine tartrate, Disodium guanosine-5'-monophosphate, Cupric sulphate, Thiamin hydrochloride, Riboflavin, Retinyl-acetate, Pyridoxine hydrochloride, Manganese sulphate, Folic acid, Potassium iodide, Phytomenadione, Sodium selenite, D-Biotin, Cholecalciferol, Cyanocobalamin. Contains: Milk, lactose and fish products."])},
      "preparationTableUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/products/preparation-table-1_en.png"])}
    }
  }
}